import React from "react";

const ErrorDisplay = () => {
  return (
    <div
      className="error-display-general"
      style={{
        position: "absolute",
        zIndex: 1000,
        top: 0,
        bottom: "30vh",
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        textShadow: "0 0 20px rgba(0,0,0,0.8)",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h2>An unexpected error occurred.</h2>
        <p>
          We are sorry for the interruption. Try reloading the page. If that
          doesn't help, please contact our customer service.
          <br />
          <a href={"mailto:support@bjelin.com"}>support@bjelin.com</a>
        </p>
        <button
          className="error-reload-button"
          style={{
            padding: "0.85em 1em",
            background: "#ffffff",
            border: "2px solid #ffffff",
            borderRadius: "6px",
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          "Reload"
        </button>
      </div>
    </div>
  );
};

export default ErrorDisplay;
